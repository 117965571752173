*{
    padding: 0;
    margin:0;
    box-sizing: border-box;
}
.header{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width:auto;
height:220px;
background-color:#80a729;
line-height: 1.5rem;
}
body {
    background-color:#fbfdfe;
    width:100%;
    
}
.content{
    width:80%;
    margin: auto;  
}
.contentSection1{
    display: flex;
    flex-direction: row;
 
    justify-content: space-around;
    padding-top:10px;
    line-height: 0.7rem;
    font-size: 20px;
}
.contentSection2{
    display: flex;
    flex-direction: row;
  
justify-content: space-between;
    padding-top:20px;
    line-height: 0.7rem;
    font-size: 16px;
}
.contentSection3{
  
   display: flex;
   align-items: center;

   padding-top: 20px;
   
}
.tableItem th,tr,td{
    border: 1px solid gray;
    padding: 10px;
    line-height: 1rem;
    
}
.firstCol{
    column-width: 40px;
}
.secondCol{
    column-width: 400px;
}
.thirdCol{
    column-width: 120px;
}
.contentSection4{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    line-height: 0.5rem;
}
.contentSection4 .section4Left4
{
    font-size: 12px;
}
.sign
{
    font-size: 12px;
}
.section4Middle{
    margin-top:100px;
}
.payImg{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.section4Right4{
    display: flex;
    flex-direction: column;
   
}

.section4Right4SubItem2{
    margin-top: 40px;
}
.section4MiddleImg1 img
{
    width: 50px;
    height:30px;
}
.section4MiddleImg2 img
{
    width: 50px;
    height:30px;
}
.section4Right4SubItem2 img
{

    width:120px ;
    height: 120px;;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
   position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  }
  
  .modalContent {
    /* Add this to your modal content styles */

    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 80vh; /* Set a maximum height for the modal content */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
 
  
      /*padding: 20px;
    border-radius: 5px;
  max-width: 600px; adjust as needed 
    
    text-align: center;*/
  }
  .modalButtons {
    position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 10px;
  }
  
  .downloadButton,
  .closeButton {
    background-color: #3498db;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .downloadButton:hover,
  .closeButton:hover {
    background-color: #2980b9;
  }