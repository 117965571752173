
*{
    padding: 0;
    margin:0;
    box-sizing: border-box;
}
body{
    background-color:  #e2e3e2;
   font-family: 'Poppins', sans-serif;
}
.main_container
{
  width:80%;
  margin: auto;
}


.loginmain_content
{
  display: flex;
  justify-content: center; /* Horizontally center the flex container */
  align-items: center; /* Vertically center the flex container */
  height: 100vh; 
}
.content_right{
  width:60%;
 display: flex;
 justify-content: center;
}
.content_left{
  width: 60%;
  display: flex;
 
}
.content_right img{
object-fit:fit;
height: 160px;
width:400px;}


.login{
 
  background-color: rgb(255,255,255);
  border-radius: 10px;
  /* box-shadow:5px  8px 8px #ebedf0 ; */
  box-shadow: 0 0 7px #707071;
padding :20px;
}

.btnstyle .customButton {
 background-color: #7fa826;
 
 color: #ebedf0;
 font-weight: bolder;

}
.btnstyle .customButton:hover
{
  background-color: #516d10;
}


 .forgetPaslink{
  
  color:  #7fa826;
  font-weight:bolder;
  text-decoration: none;

}

.modal1 {
  display: flex;
  align-items: center;
  justify-content: center;
 position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
}

.modalContent1{
  /* Add this to your modal content styles */

  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh; /* Set a maximum height for the modal content */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */


    /*padding: 20px;
  border-radius: 5px;
max-width: 600px; adjust as needed 
  
  text-align: center;*/
}

.modalButtons1 {
  position: absolute;
top: 0;
right: 0;
display: flex;
gap: 10px;
}


.closeButton {
  background-color: #80A728;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.closeButton:hover {
  background-color: #516d10;
}
.header{
   
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.logo {
    object-fit:fit;
    height: 60px;
    width:160px;
    margin-left: 10px;
 
}

.navList 
{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.profileSection
{
    display: flex;
   
    align-items: center;
    justify-content: space-evenly

}


.profileSectionItems li{
  
       
        color:#9ca9b8;
        padding:8px;
   
      
}
.toggler {
    display: none;
    cursor: pointer;
}
.hamburger {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
  }
  

  .main_content
  {
    width:80%;
    margin: auto;  
    
    
  }
  .breadcrumps{
    height:100px;
    display: flex;
    align-items: center;
   
    

}
.breadcrumps .breadcrumpsItems
{
    display: flex;
    flex-direction: row;
    list-style: none;


    font-size: 18px;

  
  
}
.breadcrumpsItems li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  .contentwrpper{
    height:2000px;
    background-color:#fff ;
    border-radius: 20px;
    padding-top: 10px;
}
.contentwrpperForm
{
    width:60%;
    background-image: linear-gradient(rgb(89, 138, 5),rgb(119,163,43),rgb(145, 210, 32));
    margin:auto;
    border-radius: 20px;
}
.contentwrpperForm .form
{
    padding:20px;
    line-height: 2rem;
}




.footer
{
    background-color: #bfbfc0;
    height:70px;
    width:80%;
    margin:auto;
    display: flex;
  align-items: center;
  justify-content: center;

}
/*invoice Page */
.contentSection1
{
    display: flex;
    flex-direction: row;
 justify-content: space-around;
 padding-top:20px;
 line-height:0.7em;
}
.section1Left
{
    display: flex;
    flex-direction: column;
   
    align-items: flex-start;
}
.section2Right
{ display: flex;
    flex-direction: column;
   align-items: flex-end;
}
.contentSection2{
   
    background-image: linear-gradient(rgb(89, 138, 5),rgb(119,163,43),rgb(145, 210, 32));
    height:auto;
    width: 95%;
    margin:auto;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
 
  justify-content: space-around;
 padding-top:20px;
 line-height:0.7em;
}
.sectionLeft
{
    display: flex;
    flex-direction: column;
  
    align-items: flex-start;
}
.sectionLeft input{
    height:30px
}
.sectionRight
{ display: flex;
    flex-direction: column;
   align-items: flex-end;
}

.Section3invoiceFields{
 height:100px;
    display: flex;
    flex-direction: 'row';
    justify-content:space-evenly;
    align-items: center;
}
.btnstyle1{
    padding:2px 10px;
}
.table-container table
{

}
.table-container table,th,td{
  border: 1px solid black;
  font-size: 0.8rem;
  overflow-x: auto;
    text-align: center;
}
.table-container table,thead{
  background-color:#83A83F;
}
.table-container th,td{
  font-style: italic;
}
  
  .table-container table th,td{
    font-size: 0.8rem;
 font-style: italic;
    word-wrap: break-word;
  overflow-wrap: break-word;
        
  }
  
/* manage client table */
/* Apply this style to make the table responsive */
@media (max-width: 600px) {
    .table-container {
      width: 100%;
      display: block;
    }
    /* Style for the stacked table rows on small screens */
    td, th {
      display: block;
      width: 25%;
        box-sizing: border-box;
        float: left;
    }
    tr::after {
        content: "";
        display: table;
        clear: both;
      }
  }
  
  @media only screen and (max-width: 1024px) {
    /* Adjust styles for tablet layout here */
    .table-container {
      overflow-x: auto;
     
    }
    td, th {
        display: block;
        width: 100%;
      }
   
  }
  
  
  @media only screen and (max-width: 768px) {
   
    .table-container {
      overflow-x: auto;
    }
    td, th {
        display: block;
        width: 100%;
      }
   
  }

  /* Dashboard */
  .searchfiels
  { height:50px;
    display : flex;
    flex-direction :row;
align-items: center;
  }

 .searchicon{
font-size: 18px;
  }
  /* InvoiceDetailsModal.css (create a new CSS file if needed) */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
}

.modalContent {
  background-color: white;
  width: 100%;
    /*padding: 20px;
  border-radius: 5px;
max-width: 600px; adjust as needed 
  
  text-align: center;*/
}

/*Edit Client */
.section1InputField
{
  display: flex;
  flex-direction: column;
 margin:auto;
  
  
}
.buttons
{
  display: flex;
  flex-direction: column;
 margin: 100px;

}
